
import React, { Component ,useState} from "react";
import PreguntaDataService from "../_services/pregunta";
import { Link } from "react-router-dom";
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, {Search, CSVExport } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';
const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const fileExtension = '.xlsx';
const { SearchBar } = Search;
const { ExportCSVButton } = CSVExport;

const columns = [
{   dataField: 'id_pregunta',   text: 'Editar' ,formatter: (rowContent, row) => {
  return (
    <>
      <div className="btn-toolbar justify-content-between" role="toolbar"
        aria-label="Toolbar with button groups">
        <div className="btn-group" role="group" aria-label="First group">
          <Link to={"/preguntaedit?id=" + rowContent}
            className="btn btn-outline btn-outline-dashed btn-outline-primary btn-icon btn-sm "               >
            <i className="fa-solid fa-edit   text-primary"></i>
          </Link>
        </div>
      </div>
    </>
  )
} }, 
      {   dataField: 'pregunta',   text: 'Pregunta',sort:true ,csvType: Text },                   
       {   dataField: 'indicador_correcta',   text: 'Alternativa Correcta',sort:true },                   
      {   dataField: 'rpta_a',   text: 'Alternativa A',sort:true },                   
      {   dataField: 'rpta_b',   text: 'Alternativa B',sort:true },                   
      {   dataField: 'rpta_c',   text: 'Alternativa C',sort:true },                   
      {   dataField: 'rpta_d',   text: 'Alternativa D',sort:true },  
      {   dataField: 'codigo_categoria',   text: 'Categoria',sort:true },  
      {   dataField: 'fecha_creacion',   text: 'Fecha',sort:true },  

                 
];
// Function to export data as a file
 const exportToCSV = (csvData, fileName) => {
  const ddlreporte = [];

   for (var i = 0, l = csvData.length; i < l; i++) {
     ddlreporte.push({
         "pregunta": csvData[i].pregunta, 
         "indicador_correcta":  csvData[i].indicador_correcta ,
         "rpta_a":  csvData[i].rpta_a,
         "rpta_b":  csvData[i].rpta_b,
         "rpta_c":  csvData[i].rpta_c,
         "rpta_d":  csvData[i].rpta_d, 
         "fecha_creacion":  csvData[i].fecha_creacion       
     })
   }
   const ws = XLSX.utils.json_to_sheet(ddlreporte);
   const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
   const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
   const data = new Blob([excelBuffer], {type: fileType});
   FileSaver.saveAs(data, fileName + fileExtension);
 }
export default class PreguntaLista extends Component {
  constructor(props) {
    super(props);
     this.state = {
      _preguntas: []
    };
  }
  componentDidMount() {
    this.obtenerregistros();
  }
  obtenerregistros() {
    PreguntaDataService.getpreguntas()
      .then(response => {
        this.setState({
          _preguntas: response.data
        });
        //console.log(response.data);
      })
      .catch(e => {
        console.log(e);
      });
  }
  render() {
    const {  _preguntas } = this.state;
    return (
        <div className="col-lg-12">
            <ToolkitProvider
            keyField="id_pregunta"
            data={ _preguntas }
            columns={ columns }
            search  
            exportCSV={{  
              fileName: 'filename.csv', 
              separator: ';',
             // blobType: "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8" 
             // blobType: "data:text/csv;charset=utf-8" 
              blobType: "data:text/csv;charset=UTF-8,%EF%BB%BFs" 
              }}         >
            {
              props => (
                <div>
                  <SearchBar { ...props.searchProps } placeholder="Buscar..." />
                  <button variant="warning" className="btn btn-success btn-sm"
                         onClick={(e) => exportToCSV(_preguntas,'UCJ-Preguntas')}>
                             <i className="fa-solid fa-file-excel "></i>Exportar</button>
                  <hr />
                  <BootstrapTable className="table table-bordered"
                    { ...props.baseProps }
                  />
                </div>
              )
            }
          </ToolkitProvider>
        </div>
    );
  }
}
 