
import React, { Component ,useState} from "react";
import ProgramaDataService from "../_services/programa";
import { Link } from "react-router-dom";
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, {Search , CSVExport } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';
const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const fileExtension = '.xlsx';
const { SearchBar } = Search;
const { ExportCSVButton } = CSVExport;

const columns = [
{   dataField: 'id_programa',   text: 'Editar' ,formatter: (rowContent, row) => {
  return (
    <>
      <div className="btn-toolbar justify-content-between" role="toolbar"
        aria-label="Toolbar with button groups">
        <div className="btn-group" role="group" aria-label="First group">
          <Link to={"/programaedit?id=" + rowContent}
            className="btn btn-outline btn-outline-dashed btn-outline-primary btn-icon btn-sm"               >
            <i className="fa-solid fa-edit   text-primary"></i>
          </Link>
          <Link to={"/planestudioedt?id=2&ref="+ rowContent}
                className="btn btn-outline btn-outline-dashed btn-outline-danger btn-icon btn-sm "            >
                <i className="bi bi-diagram-2 text-warning fs-2x"></i>
              </Link>
        </div>
      </div>
    </>
  )
} }, 
{   dataField: 'codigo',   text: 'Código',sort:true ,csvText: 'Codigo'}, 
 {   dataField: 'titulo',   text: 'Título',sort:true,csvText: 'Titulo'},
 {   dataField: 'descripcion',   text: 'Descripción' ,sort:true,csvText: 'Descripcion'},
 { dataField: 'codigo_estado', text: 'Estado', sort: true, formatter: (rowContent, row) => {
  return (
    <>
      {rowContent === "Activo" ?
        <>
          <span className="badge badge-primary">{rowContent}</span>
        </> :
        <>
          {rowContent === "Inactivo" ?
            <>
              <span className="badge badge-danger">{rowContent}</span>
            </> :
            <>
              <span className="badge badge-warning">{rowContent}</span>
            </>}
        </>}
    </>
  )
}
},
 {   dataField: 'fecha_creacion',   text: 'Creado'  ,sort:true},
 {   dataField: 'fecha_modificacion',   text: 'Modificado'  ,sort:true},
 { dataField: 'planestudio', text: 'PLan', sort: true, formatter: (rowContent, row) => {
    return (
      <>
        {rowContent == "0" ?
          <>
            <span className="badge badge-danger">{rowContent}</span>
          </> :
          <>
            <span className="badge badge-primary">{rowContent}</span>
          </>}
      </>
    )
  }
}, 
{ dataField: 'curso', text: 'Curso', sort: true, formatter: (rowContent, row) => {
  return (
    <>
      {rowContent == "0" ?
        <>
          <span className="badge badge-danger">{rowContent}</span>
        </> :
        <>
          <span className="badge badge-primary">{rowContent}</span>
        </>}
    </>
  )}
},                     
 ];

 const exportToCSV = (csvData, fileName) => {
  const ddlreporte = [];
   for (var i = 0, l = csvData.length; i < l; i++) {
     ddlreporte.push({
         "codigo": csvData[i].codigo, 
         "titulo":  csvData[i].titulo ,
         "descripcion":  csvData[i].descripcion,
         "fecha_creacion":  csvData[i].fecha_creacion        ,
         "fecha_modificacion":  csvData[i].fecha_modificacion
     })
   }
   const ws = XLSX.utils.json_to_sheet(ddlreporte);
   const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
   const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
   const data = new Blob([excelBuffer], {type: fileType});
   FileSaver.saveAs(data, fileName + fileExtension);
 }
export default class ProgramaLista extends Component {
  constructor(props) {
    super(props);
     this.state = {
      _programas: []
    };
  }
  componentDidMount() {
    this.obtenerregistros();
  }
  obtenerregistros() {
    ProgramaDataService.getprogramas()
      .then(response => {
        this.setState({
          _programas: response.data
        });
        console.log(response.data);
      })
      .catch(e => {
        console.log(e);
      });
  }
  render() {
    const {  _programas } = this.state;
    return (
        <div className="col-lg-12">
          <ToolkitProvider
            keyField="id_programa"
            data={ _programas }
            columns={ columns }
            search  
            exportCSV={{  
              fileName: 'programa.csv',
              separator: ';',
              noAutoBOM: false 
              }}              >
            {
              props => (
                <div>
                  <SearchBar { ...props.searchProps } placeholder="Buscar..." />
                  <button variant="warning" className="btn btn-success btn-sm"
                         onClick={(e) => exportToCSV(_programas,'UCJ-Programa')}>
                             <i className="fa-solid fa-file-excel "></i>Exportar</button>
                  <hr />
                  <BootstrapTable className="table table-responsive table-bordered"
                    { ...props.baseProps }
                  />
                </div>
              )
            }
          </ToolkitProvider>
        </div>
    );
  }
}
 