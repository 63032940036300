import React, { useCallback, Component, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import PlanDataService from '../_services/planestudio';

import LoadingSpinner from "../_components/LoadingSpinner";
import UsuarioDataService from "../_services/usuario";
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { Search, CSVExport } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import paginationFactory from 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator';
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';
import planestudio from "../_services/planestudio";
const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const fileExtension = '.xlsx';
const { ExportCSVButton } = CSVExport;
const { SearchBar } = Search;
const API_BASE_URL = "https://apiucj.azurewebsites.net/api/reporte/usuario/";

const options = {
    sizePerPageList: [{
      
      text: '100', value: 100
    } , {
        text: '250', value: 250
      }, {
        text: '500', value: 500
      }, {
        text: '1000', value: 1000
      }] // A numeric array is also available. the purpose of above example is custom the text
  };
const styledata ={
  fontSize: '10px'
}
const columns = [
          {   dataField: 'codigo_usuario',   text: 'Código',  style:  styledata ,sort:true,headerStyle: { color: '#fff', backgroundColor: '#009ef7' },formatter: (rowContent, row) => {
            return (
               <>
                <Link className="breadcrumb-item pe-3"      to={"/usuarioreporte?id=" + row["id_usuario"]}             >
                  {rowContent}
                </Link>
                </>
            )
          } },        
          {   dataField: 'personal',   text: 'Personal',  style: { fontSize: '10px'},sort:true,headerStyle: { color: '#fff', backgroundColor: '#009ef7' } },                             
          
          {   dataField: 'correo_electronico',   text: 'Email',  style: { fontSize: '10px'},sort:true,headerStyle: {  color: '#fff', backgroundColor: '#009ef7' },formatter: (rowContent, row) => {
            return (
               <>
                <span>{rowContent}</span>
                </>
            )
          } },      
          {   dataField: 'correo_secundario',   text: 'Email2',  style: { fontSize: '10px'},sort:true,headerStyle: { color: '#fff', backgroundColor: '#009ef7' } },                
          {   dataField: 'telefono',   text: 'Teléfono',headerStyle: { color: '#fff', backgroundColor: '#009ef7' },formatter: (rowContent, row) => {
            return (
               <>
                <a className="text-primary text-underline"  target={"_blank"} href={"https://wa.me/51"+rowContent}  >
                  {rowContent}
                </a>
                </>
            )
          } },                  
          {   dataField: 'departamento',   text: 'Dpto',  style: { fontSize: '10px'},sort:true,headerStyle: { color: '#fff', backgroundColor: '#009ef7' } },                   

          {   dataField: 'centro_costo',   text: 'CeCo',  style: { fontSize: '10px'},sort:true,headerStyle: { color: '#fff', backgroundColor: '#009ef7' } },                   
          {   dataField: 'centrocosto',   text: 'CeCo',  style: { fontSize: '10px'},sort:true,headerStyle: { color: '#fff', backgroundColor: '#009ef7' } },                   
          {   dataField: 'genero',   text: 'Genero',  style: { fontSize: '10px'},sort:true,headerStyle: { color: '#fff', backgroundColor: '#009ef7' } },                   
          {   dataField: 'cargo',   text: 'Cargo',  style: { fontSize: '10px'},sort:true,headerStyle: { color: '#fff', backgroundColor: '#009ef7' } },      
          {   dataField: 'codigo_grupo',   text: 'Grupo',  style: { fontSize: '10px'},sort:true ,headerStyle: { color: '#fff', backgroundColor: '#009ef7' }},                    
          {   dataField: 'fecha_ingreso',   text: 'Ingreso',  style: { fontSize: '10px'},sort:true ,headerStyle: { color: '#fff', backgroundColor: '#009ef7' }},                   
          {   dataField: 'empresa',   text: 'Empresa',sort:true,  style: { fontSize: '10px'},headerStyle: { color: '#fff', backgroundColor: '#009ef7' } },                   
          {   dataField: 'tipo_documento',   text: 'TpoDoc',  style: { fontSize: '10px'},sort:true,headerStyle: { color: '#fff', backgroundColor: '#009ef7' } },                   
          {   dataField: 'codigo_jefe_inmediato',   text: 'Cod Jefe',  style: { fontSize: '10px'},sort:true,headerStyle: { color: '#fff', backgroundColor: '#009ef7' } },      
          {   dataField: 'jefe_inmediato',   text: 'Jefe',  style: { fontSize: '10px'},sort:true,headerStyle: { color: '#fff', backgroundColor: '#009ef7' } },                   
          {   dataField: 'fecha_cese',   text: 'Fecha Cese',  style: { fontSize: '10px'},sort:true,headerStyle: { color: '#fff', backgroundColor: '#009ef7' } },                   
          {   dataField: 'fecha_login',   text: 'Ultimo Acceso',  style: { fontSize: '10px'},sort:true ,headerStyle: { color: '#fff', backgroundColor: '#009ef7' }},                   
          {   dataField: 'hora_login',   text: 'Hora Acceso',  style: { fontSize: '10px'},sort:true ,headerStyle: { color: '#fff', backgroundColor: '#009ef7' }},                   
    
                        
    ];
const exportToCSV = (csvData, fileName) => {
  const ddlreporte = [];

      for (var i = 0, l = csvData.length; i < l; i++) {
        ddlreporte.push({
            "codigo_estado": csvData[i].codigo_estado, 
            "codigo_usuario":  csvData[i].codigo_usuario ,
            "personal":  csvData[i].personal,
            "correo_electronico":  csvData[i].correo_electronico        ,
            "telefono":  csvData[i].telefono,
            "centro_costo":  csvData[i].centro_costo,
            "genero":  csvData[i].genero,
            "cargo":  csvData[i].cargo,
            "codigo_grupo":  csvData[i].codigo_grupo,
            "fecha_ingreso":  csvData[i].fecha_ingreso,
            "empresa":  csvData[i].empresa,
            "tipo_documento":  csvData[i].tipo_documento,
            "codigo_jefe_inmediato":  csvData[i].codigo_jefe_inmediato,
            "jefe_inmediato":  csvData[i].jefe_inmediato,
            "fecha_cese":  csvData[i].fecha_cese,
            "fecha_login":  csvData[i].fecha_login,
            "hora_login":  csvData[i].hora_login
        })
      }
      const ws = XLSX.utils.json_to_sheet(ddlreporte);
      const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
      const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
      const data = new Blob([excelBuffer], {type: fileType});
      FileSaver.saveAs(data, fileName + fileExtension);
    }
const UsuarioPlanEstudio = () => {
    const [datos, setDatos] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [plan, setPlan] = useState([]);
    const queryParameters = new URLSearchParams(window.location.search)
    const idplanestudio = queryParameters.get("id")
    useEffect(() => {
        //setIsLoading(true)
        PlanDataService.getplanestudioById(idplanestudio)
          .then(response => response.json())
          .then(result => {
            setPlan(result);
            console.log(result);
          })
          .catch(e => {
            console.log(e);
          });
        UsuarioDataService.getUsuariosplan(idplanestudio)
                .then(result => {
                    setDatos(result.data);
                    console.log(result.data)
                    setIsLoading(false)
                })
                .catch(e => {
                    console.log(e);
                });

    }, [isLoading]);
    if (isLoading) {
        return <> <LoadingSpinner />  </>;
    }
    return (
        <div className="card card-custom">
            <div className="card-header pt-5">
                <h3 className="card-title align-items-start flex-column">
                    <span className="card-label fw-bold text-dark">Listado de Usuarios Asignados Plan:
                     
                    </span>
                    <span className="text-danger mt-1 fw-semibold fs-6"> { plan?.denominacion}</span>
                </h3>
                <div className="card-toolbar">
                <Link to={"/planestudio"}
                                                className="btn btn-icon-white btn-text-white btn-danger btn-sm">
                                                <i className="fa-solid fa-reply "></i>
                                                Volver
                                            </Link>
                     {datos?<>
                      <button variant="warning" className="btn btn-success btn-sm"
                         onClick={(e) => exportToCSV(datos,'UCJ-Usuarios Asignados')}>
                             <i className="fa-solid fa-file-excel "></i>Exportar</button>
                    </>:<></>}
                  
                </div>
            </div>
            <div className="card-body">
            <div className="table-responsive">
               
                <ToolkitProvider
                    keyField="id_asignacion"
                    data={datos}
                    columns={columns}
                    search           >
                    {
                        props => (
                            <div className="table-responsive">
                                <SearchBar { ...props.searchProps } placeholder="Buscar..." />
                                <BootstrapTable className="table table-bordered table-responsive"
                                 pagination={ paginationFactory(options) }
                                    {...props.baseProps}
                                />
                            </div>
                        )
                    }
                </ToolkitProvider>
                </div>
            </div>

        </div>
    );

};

export default UsuarioPlanEstudio;
